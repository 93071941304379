<template>
  <ditto-wireframe v-bind="wireframeOptions" :dark="darkMode">
    <!-- default slot -->
    <router-view />

    <!-- fiexed slots -->
    <template v-slot:navLeft>
      <router-view name="sidebar" />
    </template>

    <!-- route dependant slots -->
    <template v-slot:bar="{ className, ...data }">
      <router-view
        name="bar"
        :class="className"
        v-bind="data"
        :dark="darkMode"
      />
    </template>

    <template
      v-if="$route.name === 'viewer' || $route.name === 'shared'"
      v-slot:nav="{ className, ...data }"
    >
      <router-view
        name="nav"
        :class="className"
        v-bind="data"
        :dark="darkMode"
      />
    </template>

    <v-snackbar
      :color="snackbar.color"
      elevation="0"
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          id="S1"
          text
          v-bind="attrs"
          @click="closeSnackbar"
          :dark="darkMode"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ditto-wireframe>
</template>

<script>
import { DoubleNavigationWireframe } from "@/../ditto/wireframes";
import { mapState } from "vuex";

export default {
  // eslint-disable-next-line
  name: "Home",
  components: { DittoWireframe: DoubleNavigationWireframe },
  data: () => ({
    wireframeOptions: {
      bar: {
        mobileMenuComponent: () => import("@/components/MobileTopMenu")
      },
      nav: { width: 220 },
      navLeft: { color: "primary", width: 70 }
    }
  }),
  computed: {
    ...mapState(["darkMode"]),
    snackbar: {
      get: function() {
        return this.$store.state.snackbar;
      },
      set: function() {
        this.$store.commit("closeSnackbar");
      }
    }
  },
  methods: {
    closeSnackbar() {
      this.$store.dispatch("closeSnackbar");
    }
  }
};
</script>
