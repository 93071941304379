import Vue from "vue";
import App from "./App.vue";
import i18n from "@/plugins/i18n";
import LogRocket from "logrocket";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vue2TouchEvents from "vue2-touch-events";
// import { isMobileOrTablet } from "./js/utils.general";
import "typeface-roboto";

Vue.config.productionTip = false;

// Detect customization
if (process.env.NODE_ENV !== "production" && process.env.APP_CUSTOMER) {
  console.warn("=== Detected customer options:", process.env.APP_CUSTOMER);
}

// Touch events
Vue.use(Vue2TouchEvents, {
  disableClick: true, // isMobileOrTablet()
  namespace: "touch-event",
  touchHoldTolerance: 5
});

// Ditto modules
// Authentication
import authPlugin from "@/../ditto/auth";
import AuthWrapper from "@/components/AuthWrapper";
Vue.prototype.$isDemoUser = false;
Vue.prototype.$isAdminUser = false;

Vue.use(authPlugin, {
  addTrailingSlashInterceptor: true,
  allowUserRegistration: false,
  onLoginSuccess: user => {
    Vue.prototype.$ditto.dicom.reset();
    Vue.prototype.$isDemoUser = user.is_demo;
    Vue.prototype.$isAdminUser = user.is_admin;
  },
  redirectGuestUsers: "/login",
  router,
  store,
  wrapperComponent: AuthWrapper,
  logrocket: {
    module: LogRocket,
    customString: process.env.APP_CUSTOMER.logrocket_env
  }
});

// DICOM pack
import dicomPlugin from "@/../ditto/dicom";

const lightTheme = vuetify.userPreset.theme.themes.light;

let toolsStyle = {
  activeColor: lightTheme.accent,
  backgroundColor: "rgba(1, 1, 1, 0.85)",
  color: lightTheme.dicomtool,
  fillColor: lightTheme.dicomtool,
  fontFamily: "Comfortaa",
  fontSize: 14,
  width: 2
};

Vue.use(dicomPlugin, {
  dataTypes: true,
  pacs: true,
  series: true,
  store,
  utils: true,
  toolsStyle
});

// Mobile plugin
import mobilePlugin from "@/../ditto/mobile";
Vue.use(mobilePlugin);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
